body {
  background-color: #444;
}

.main {
  background-color: #fff;
}

#home_title h1 {
  padding-bottom: 0.7em;
}

#home_title p, #home_title ul, #home_title li {
}

#home h2 {
  font-size: 20px;
  color: #555;
}

#home {
  min-height: 6em;
  margin-bottom: 4em;
}

#home {
  font-size: 18px;
}

p, .font-serif {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
}

.links {
  margin-top: 2em;
  margin-bottom: 1em;
  padding-bottom: 0em;
  a {
    font-size: 14px;
  }
}

.title {
  font-weight: bold;
  font-size: 45px;
  margin-top: 1em;
}

h1, h2, h3, h4, .font-sans {
  /* tailwind .font-sans */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

h1, h2, h3, h4 {
  font-weight: bold;
}

h1 {
  margin-top: 0;
  margin-bottom: 0.3em;
  line-height: 1.2em;
  letter-spacing: -1px;
}

h2, h3, h4 {
  margin-bottom: 0.5em;
  line-height: 1.3em;
}

.no_indent {
  text-indent: 0;
}

p, li {
  font-family: 'PT Serif', Georgia, Times, 'Times New Roman', serif;
  font-size: 19px;
}

.post p, .post li {
  text-indent: 0em;  
}

.post img {
  max-width: 90%;
}

.post_wrapper {
  margin-top: 2em;
}

.meta {
  font-size: 16px;
  color: #aaa;
  margin-right: 1em;
  margin-bottom: 0.5em;
  margin-top: 0; 
}

header {
  margin-top: 0;
  padding: 0.15em;
  color: #fff;
  background-color: #222;
  a {
    color: #fff;
  }
  a:hover {
    color: #aaa !important;
  }
  h2 {
    font-size: 20px;
    padding: 0.2em;
    margin: 0;
  }
}

.header p {
  font-size: 19px;
}

.rounded {
  border-radius: 50%;
  overflow: hidden;
  width: 80px;
  height: 80px;
  img {
    width: 100%;
  }
}

.home_pic {
  float: right;
  margin: 0 auto;
  width: 100px;
  height: 100px;
}

ul {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}

ul li {
  text-indent: 0em;
}

ul li:before {
  display: inline-block;
  content: "-";
  width: 1em;
  margin-left: -0.4em;
}

#blog_notes {
  color: #777;
  a { 
    color: #777;
  };
  p, li {
    font-size: 13px;
  }
}

#home_title {
  a {
    color: #333;
  }
}

/* credit: https://css-tricks.com/snippets/css/simple-and-nice-blockquote-styling/ */

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

.note, .cta {
  padding: 1em 1.2em;
  margin-bottom: 0.5em;
  border-radius: 4px;
  p {
    margin-bottom: 0px;
  }
}

.note {
  background-color: rgb(0,145,228);
  border: 1px solid darken(rgb(221,224,235), 5%);
  p {
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  color: #fff;
  a {
    color: #fff;
    text-decoration: underline;
  }
  .fa {
    color: #FF0;
  }
}

a.btn {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.cta {
  background-color: transparentize(#FF0, 0.5);
  border: 1px solid transparentize(darken(#FF0, 10%), 0.5);
  border-radius: 8px;
  a {
    color: #333;
    text-decoration: underline;
  }
}

.highlight {
  margin-top: 1em;
}

header {
  a:hover, a:hover h2 {
    color: #eee !important;
  }
}

.footer {
  text-align: center;
  font-size: 13px;
  color: #888;
  padding-bottom: 2em;
}

.subscribe_form_wrapper {
  padding: 0.5em 1em;
  background-color: rgb(0,145,228);
  margin-bottom: 4em;
  border-radius: 5px;
}

.post .subscribe_form_wrapper {
  border-radius: 5px;
  margin-bottom: 0;
  margin-top: 1.5em;
}

.subscribe_form {
  label {
    font-size: 16px;
//    font-family: 'PT Serif', Georgia, Times, 'Times New Roman', serif;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #ffffff;
  }
  text-align: center;
  #tlemail {
    width: 10em !important;
    margin-left: 0.5em;
  }
}

.embed-responsive {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.testimonial {
  background-color: rgba(50, 50, 100, .10);
  border-radius: 5px;
  padding: 0.5em 1em;
  margin-bottom: 1em;
  margin-top: 1em;
  .content {
    margin: 0.5em 0.5em;
    font-style: italic;
    color: rgba(50, 50, 100, .55);
  }
  .signature {
    margin: 0.5em 0.5em;
  }
  img {
    display: inline-block;
    max-height: 25px;
    vertical-align: middle;
    margin-bottom: 3px;
    margin-left: -7px;
  }
}

// fix for invisible colon
.language-yaml .pi {
  color: #75715e;
}

.language-shell code {
  color: #aaa !important;
}

.social {
  background-color: #951FDE30;
  
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 4px;
  padding-bottom: 0px;
  margin-bottom: 1em;
}

.social-button {
  display: inline-block;
  margin-right: 1em;
  div.icon {
    display: inline-block;
  }
  svg {
    height: 18px;
  }
  .social-name {
    vertical-align: top;
    color: #951FDE;
    text-transform: lowercase;
  }
}

.photo_legend {
  padding-top: 1em;
  padding-bottom: 1em;
}